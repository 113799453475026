const appConfig = {
  // apiPrefix: "http://localhost:5002/api/",
  apiPrefix: "https://vktestcar.5techg.com/api/",
  // apiPrefix: "https://6q27kpdw-5000.inc1.devtunnels.ms/api/",
  webhookPrefix: "https://webhooks.vkrepo.in/webhooks/",
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
};

export default appConfig;
